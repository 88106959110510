<script lang="ts">
  import { getCurrentLocale, useTranslations } from "@/lib/utils/i18n";
  import { acceptCookies } from "@/lib/client/stores";

  const { t } = useTranslations(getCurrentLocale());
</script>

<div class="pointer-events-none fixed bottom-4 left-4 right-4 z-50">
  {#if $acceptCookies === undefined}
    <div
      class="card pointer-events-auto mx-auto w-full max-w-md overflow-hidden rounded-xl bg-gradient-to-r from-purple-500 to-indigo-500 text-white shadow-md"
    >
      <div
        class="absolute right-0 top-0 -translate-y-1/3 translate-x-1/3 select-none text-[6rem] leading-none opacity-20"
      >
        🍪
      </div>

      <div class="card-body z-10 p-4 text-center">
        <h2 class="card-title mb-1 text-xl font-semibold">
          {t("cookieBanner.title")}
        </h2>
        <p class="mb-3 text-sm">
          {t("cookieBanner.description")}
        </p>
        <div class="card-actions flex justify-center space-x-3">
          <button
            class="btn btn-outline border-white px-3 py-1 text-white hover:bg-white hover:text-indigo-500"
            on:click={() => ($acceptCookies = "false")}
          >
            {t("cookieBanner.deny")}
          </button>
          <button
            class="btn btn-primary bg-white px-3 py-1 text-indigo-500 hover:bg-indigo-600 hover:text-white"
            on:click={() => ($acceptCookies = "true")}
          >
            {t("cookieBanner.accept")}
          </button>
        </div>
      </div>
    </div>
  {/if}
</div>

<style>
  .btn:active {
    animation: clickEffect 0.2s ease-in-out;
  }

  @keyframes clickEffect {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(0.9);
    }
    100% {
      transform: scale(1);
    }
  }
</style>
